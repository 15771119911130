<div class="text-xs md:text-base sticky top-0 shadow-md z-20 mx-auto">
  <nav
    class="rounded p-2 bg-white w-full h-16 flex justify-between items-center"
  >
    <VueMultiselect
      ref="companySelect"
      class="block left-7 md:left-0"
      v-model="selectCompany"
      @search-change="triggerSearch"
      :searchable="searchable"
      placeholder="Nhập tên hoặc MST để tìm kiếm công ty..."
      label="text"
      :options="companySelect"
      :internal-search="false"
      :loading="searchCompanyLoading"
      :close-on-select="false"
      showNoOptions="true"
      @select="handleSelectCompany($event)"
      @scroll.passive="handleScroll"
      deselectLabel=""
      selectLabel=""
      v-show="
        showSelect
        && companySelect.length
        && routeName != 'companyManagement'
        && routeName != 'editInvoices'
        && routeName != 'users'
        && routeName != 'profile'
        && routeName != 'invoice_summary'
        && routeName != 'system-statistic'
      "
      autocomplete="'new-password'"
    >
      <template slot="option" slot-scope="props"> {{props}} </template>
    </VueMultiselect>

    <search-input
      v-show="routeName == 'companyManagement' && role != 'CompanyUser' && role != 'CompanyAdmin'"
      class="w-1/2 max-w-md relative left-7 md:left-0"
      size="medium"
      v-model="search"
      :isIcon="true"
      placeholder="Nhập mã số thuế hoặc tên doanh nghiệp..."
      @inputSearch="search = $event"
      @doneTyping="searchInput()"
    />

    <search-input
      v-show="routeName == 'users'"
      class="w-1/2 max-w-md relative left-7 md:left-0"
      size="medium"
      v-model="searchUserName"
      :isIcon="true"
      placeholder="Tìm kiếm theo tên hoặc email"
      @inputSearch="searchUserName = $event"
      @doneTyping="searchInputUserName()"
    />


    <p
      v-if="routeName == 'editInvoices' && getDetailInvoice && getDetailInvoice.company"
      class="pl-8 md:pl-3"
    >
      {{getDetailInvoice.company.tenDoanhNghiep}}
    </p>
    <p
      v-else-if="getUserIndexRole <= 2 && !getTargetCompany"
      class="pl-8 md:pl-3"
    >
      Bạn chưa có công ty
    </p>
    <div class="block" style="visibility: hidden"></div>
    <!-- <changeLanguage class="hidden lg:flex" /> -->

    <div class="mx-1 text-right flex items-center">
      <div class="flex items-center cursor-pointer" @click="isInfor = !isInfor">
        <div class="mr-2">
          <p class="font-bold text-gray-600 cut-text text-sm">
            {{userInfo.emailQuanLyTaiKhoan}}
          </p>
          <span class="text-gray-400 flex justify-between">
            <span class="mr-2 capitalize italic">{{ userInfo.plan }}</span>
            <span v-if="getUserIndexRole != 4">{{userInfo.userType}}</span>
          </span>
        </div>
        <img
          v-if="userInfo.avatar_url"
          :src="userInfo.avatar_url"
          alt=""
          class="w-9 h-9 hidden sm:block object-cover avatar"
        />
        <img
          v-else
          src="@/assets/icons/icon_user_1.svg"
          alt=""
          class="w-9 h-9 hidden sm:block object-cover avatar"
        />
      </div>

      <button
        v-show="isInfor"
        @click="isInfor = false"
        tabindex="-1"
        class="fixed inset-0 h-full w-full cursor-default"
      ></button>

      <div class="inline-block text-right">
        <div
          class="
            absolute
            right-1
            top-14
            w-60
            rounded-sm
            dark-shadow
            bg-white
          "
        >
          <div v-show="isInfor" role="none">
            <router-link :to="{name: 'profile'}" @click="isInfor = false">
              <div
                class="
                  text-gray-700
                  hover-nav
                  flex
                  items-center
                  px-4
                  py-2
                  text-sm
                  justify-start
                  cursor-pointer
                font-bold
                "
              >
                <i class="el-icon-s-custom mr-2"/>
                Thông tin
              </div>
            </router-link>
            <a
              class="
                text-gray-700
                hover-nav
                flex
                items-center
                px-4
                py-2
                text-sm
                justify-start
                cursor-pointer
                font-bold
              "
              href="https://tokhaithue.vn/documents/201223_iTAX_-_Bao_gia_DV_quan_ly_hoa_don_dien_tu.pdf"
              target="_blank"
            >
              <i class="el-icon-money mr-2"/>
              Báo giá
            </a>
            <a
              class="
                text-gray-700
                hover-nav
                flex
                items-center
                px-4
                py-2
                text-sm
                justify-start
                cursor-pointer
                font-bold
              "
              href="https://tokhaithue.vn/documents/Thoa_thuan_dich_vu.pdf"
              target="_blank"
            >
              <i class="el-icon-notebook-2 mr-2"/>
              Thỏa thuận dịch vụ
            </a>
            <a
              class="
                text-gray-700
                hover-nav
                flex
                items-center
                px-4
                py-2
                text-sm
                justify-start
                cursor-pointer
                font-bold
              "
              href="https://tokhaithue.vn/documents/Chinh_sach_quyen_rieng_tu.pdf"
              target="_blank"
            >
              <i class="el-icon-lock mr-2"/>
              Chính sách quyền riêng tư
            </a>

            <div
              class="
                text-gray-700
                hover-nav
                flex
                items-center
                px-4
                py-2
                text-sm
                justify-start
                cursor-pointer
                font-bold
              "
              @click="signout"
            >
              <i class="el-icon-d-arrow-left mr-2"/>
              Đăng xuất
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
