<div>
  <div class="text-black md:hidden z-50 fixed top-7 left-4">
    <button
      @click="changeSidebar(hideSidebar)"
      class="mobile-menu-button focus:bg-white"
    >
      <img src="@/assets/icons/icon_bar.svg" alt="" class="w-5 h-5" />
    </button>
  </div>
  <div
    class="
      shadow-right
      md:h-screen
      bg-white
      md:z-10 md:sticky md:top-0
      overflow-y-scroll
    "
  >
    <div
      v-show="hideSidebar"
      class="
        md:mt-0
        bg-white
        md:bg-none
        sidebar
        text-black
        w-56
        h-full
        py-2
        px-2
        inset-y-0
        left-0
        transform
        z-10
        md:relative md:translate-x-0
        transition
        duration-200
        ease-in-out
        fixed
        big-sidebar
      "
    >
      <div class="text-primary hidden md:block cursor-pointer space-x-2 px-2">
        <div
          class="text-xl font-extrabold flex justify-between items-center"
          @click="changeSidebar(hideSidebar)"
        >
          <div class="w-44">{{$t("sidebar.name")}}</div>
          <div class="ml-4">
            <img
              src="@/assets/icons/icon_bar.svg"
              alt=""
              class="w-4 h-4 fill-bar"
            />
          </div>
        </div>
      </div>
      <nav class="mt-12 md:mt-2">
        <router-link
          :to="{name: 'companyManagement'}"
          class="
            py-2.5
            px-4
            rounded
            transition
            duration-200
            hover-sidebar
            flex
            items-center
          "
        >
          <img
            src="@/assets/icons/icon_company.svg"
            alt=""
            class="w-4 h-4 fill-color"
          />
          <span class="ml-3">Quản lý công ty</span>
        </router-link>
        <router-link
          :to="{name: 'users'}"
          v-if="roleUser != 'CompanyUser'"
          class="
            py-2.5
            px-4
            rounded
            transition
            duration-200
            hover-sidebar
            flex
            items-center
          "
        >
          <img
            src="@/assets/icons/icon_user.svg"
            alt=""
            class="w-4 h-4 fill-color"
          />
          <span class="ml-3">Người dùng</span>
        </router-link>
        <router-link
          v-if="roleUser != 'ITaxGeneral' && roleUser != 'SaleAdmin'"
          :to="{name: 'dashBoard'}"
          class="
            py-2.5
            px-4
            rounded
            transition
            duration-200
            hover-sidebar
            flex
            items-center
          "
        >
          <img
            src="@/assets/icons/icon_dash_board.svg"
            alt=""
            class="w-4 h-4 fill-color"
          />
          <span class="ml-3">Thống kê</span>
        </router-link>
        <router-link
          v-if="roleUser != 'ITaxGeneral' && roleUser != 'SaleAdmin'"
          :to="{name: 'invoices'}"
          class="
            py-2.5
            px-4
            rounded
            transition
            duration-200
            hover-sidebar
            flex
            items-center
          "
        >
          <img
            src="@/assets/icons/icon_invoice.svg"
            alt=""
            class="w-4 h-4 fill-color"
          />

          <span class="ml-3">Hóa đơn</span>
        </router-link>
        <router-link
          v-if="roleUser != 'ITaxGeneral' && roleUser != 'SaleAdmin'"
          :to="{name: 'reports'}"
          class="
            py-2.5
            px-4
            rounded
            transition
            duration-200
            hover-sidebar
            flex
            items-center
          "
        >
          <img
            src="@/assets/icons/icon_report.svg"
            alt=""
            class="w-4 h-4 fill-color"
          />
          <span class="ml-3">Báo cáo</span>
        </router-link>
        <router-link
          v-if="roleUser != 'ITaxGeneral' && roleUser != 'SaleAdmin'"
          :to="{name: 'invoiceLog'}"
          class="
            py-2.5
            px-4
            rounded
            transition
            duration-200
            hover-sidebar
            flex
            items-center
          "
        >
          <img
            src="@/assets/icons/icon_history.svg"
            alt=""
            class="w-4 h-4 fill-color"
          />
          <span class="ml-3">Lịch sử</span>
        </router-link>
        <router-link
          v-if="roleUser != 'ITaxGeneral' && roleUser != 'SaleAdmin'"
          :to="{name: 'mailBox'}"
          class="
            py-2.5
            px-4
            rounded
            transition
            duration-200
            hover-sidebar
            flex
            items-center
          "
        >
          <img
            src="@/assets/icons/icon_mailbox.svg"
            alt=""
            class="w-4 h-4 fill-color"
          />
          <span class="ml-3">Hòm thư</span>
        </router-link>
        <router-link
          v-if="roleUser != 'SaleAdmin'"
          :to="{name: 'invoice_summary'}"
          class="
            py-2.5
            px-4
            rounded
            transition
            duration-200
            hover-sidebar
            flex
            items-center
          "
        >
          <img
            src="@/assets/icons/icon_summary_itax_admin.svg"
            alt=""
            class="w-4 h-4 fill-color"
          />
          <span class="ml-3">Tổng hợp dữ liệu</span>
        </router-link>
        <router-link
          v-if="roleUser != 'ITaxGeneral' && roleUser != 'SaleAdmin'"
          :to="{name: 'connect-gov'}"
          class="
            py-2.5
            pl-4
            pr-0
            rounded
            transition
            duration-200
            hover-sidebar
            flex
            items-center
          "
        >
          <img
            src="@/assets/icons/icon_bank.svg"
            alt=""
            class="w-4 h-4 fill-color"
          />
          <span class="ml-3">Kết nối cơ quan thuế</span>
        </router-link>
        <router-link
          v-if="roleUser == 'ITaxAdmin'"
          :to="{name: 'system-statistic'}"
          class="
            py-2.5
            px-4
            rounded
            transition
            duration-200
            hover-sidebar
            flex
            items-center
          "
        >
          <i class="w-4 h-4 el-icon-setting"></i>
          <span class="ml-3">Thống kê hệ thống</span>
        </router-link>

        <div v-if="getUserIndexRole == 6">
        <el-tooltip
          class="item"
          effect="dark"
          placement="right"
        >
          <template #content> <div v-html="user_emails" /> </template>
          <div
            class="
              py-2.5
              pl-3
              pr-3
              rounded
              transition
              duration-200
              hover-sidebar
              flex
              items-center
              justify-between
              users-online
            "
          >
            <span class="flex items-center gap-2">
              <i class="el-icon-user"></i>
              <p class="text-xs">User Online:</p> <b> {{ total_online }} </b>
            </span>
          </div>
          </el-tooltip>
        </div>

        <div :class="taskCount <= 0 ? 'opacity-20': ''" v-if="getUserIndexRole == 6">
          <a
            :href="taskManagementUrl"
            class="
              py-2.5
              pl-4
              pr-0
              rounded
              transition
              duration-200
              hover-sidebar
              flex
              items-center
              justify-between
              pending-task
            "
            target="_blank"
          >
            <span class="flex items-center gap-2">
              <img
                src="@/assets/icons/task.svg"
                alt=""
                class="inline-block w-4 h-4 fill-color"
              />
              <p class="text-xs">Jobs Queue:</p> <b> {{ taskCount }} </b>
              <i class="el-icon-loading" v-if="taskCount > 0"></i>
            </span>
          </a>
        </div>

        <div :class="failedLookupInvoices.count <= 0 ? 'disabled': ''" v-if="getUserIndexRole == 6">
        <!-- <a -->
        <!--   class=" -->
        <!--     py-2.5 -->
        <!--     pl-4 -->
        <!--     pr-0 -->
        <!--     rounded -->
        <!--     transition -->
        <!--     duration-200 -->
        <!--     hover-sidebar -->
        <!--     flex -->
        <!--     items-center -->
        <!--     justify-between -->
        <!--     failed-lookup-info -->
        <!--   " -->
        <!--   @click.prevent="handleLookupAllInvoiceStatusFailed" -->
        <!-- > -->
        <!--   <span class="flex items-center gap-2"> -->
        <!--     <i class="el-icon-warning"></i> -->
        <!--     <p class="text-xs">HĐ chưa có trạng thái:</p> -->

        <!--     <b v-if="!failedLookupInvoices.isUpdating" class="text-warning"> {{ failedLookupInvoices.count }} </b> -->
        <!--     <i class="el-icon-loading" v-else></i> -->
        <!--   </span> -->
        <!-- </a> -->
          </div>
        <!-- <div>
          <input
            type="checkbox"
            value="selected"
            id="toggle-one"
            class="toggle-input"
            @click="isChecked = !isChecked"
          />
          <label
            id="sub-menu"
            class="
              cursor-pointer
              block
              py-2.5
              px-4
              rounded
              transition
              duration-200
              hover-sidebar
            "
            for="toggle-one"
          >
            <div class="flex justify-between">
              <div>
                <i class="fas fa-th-list"></i>
                <span class="ml-3">{{$t("sidebar.todo")}}</span>
              </div>
              <div>
                <i class="fas fa-chevron-down"></i>
              </div>
            </div>
          </label>
          <div
            role="toggle"
            class="ml-3 mega-menu sm:mb-0 shadow-xl bg-gray-100 rounded"
          >
            <router-link
              :to="{name: 'todo'}"
              class="
                block
                py-2.5
                px-4
                rounded
                transition
                duration-200
                hover-sidebar
              "
            >
              <i class="fas fa-magnet"></i>
              <span class="ml-3">Todo One</span>
            </router-link>
          </div>
          <div
            role="toggle"
            class="ml-3 mega-menu sm:mb-0 shadow-xl bg-gray-100 rounded"
          >
            <router-link
              :to="{name: 'todo1'}"
              class="
                block
                py-2.5
                px-4
                rounded
                transition
                duration-200
                hover-sidebar
              "
            >
              <i class="fas fa-tooth"></i>
              <span class="ml-3">Todo Two</span>
            </router-link>
          </div>
        </div> -->
      </nav>
    </div>

    <div
      v-show="!hideSidebar"
      class="
        hidden
        md:block
        sidebar
        text-primary
        w-14
        h-full
        py-2
        px-2
        absolute
        inset-y-0
        left-0
        transform
        -translate-x-full
        md:relative md:translate-x-0
        transition
        duration-200
        ease-in-out
      "
    >
      <a
        href="#"
        class="flex items-center justify-center h-7 space-x-2"
        @click="changeSidebar(hideSidebar)"
      >
        <img src="@/assets/icons/icon_bar.svg" alt="" class="w-4 h-4" />
      </a>
      <nav class="mt-2">
        <el-tooltip
          class="item"
          effect="dark"
          content="Quản lý công ty"
          placement="right"
        >
          <router-link
            :to="{name: 'companyManagement'}"
            class="
              flex
              py-2.5
              px-1
              rounded
              transition
              duration-200
              justify-center
              hover-sidebar
            "
          >
            <img
              src="@/assets/icons/icon_company.svg"
              alt=""
              class="w-4 h-4 fill-color"
            />
          </router-link>
        </el-tooltip>
        <el-tooltip
          v-if="roleUser != 'CompanyUser'"
          class="item"
          effect="dark"
          content="Người dùng"
          placement="right"
        >
          <router-link
            :to="{name: 'users'}"
            v-if="roleUser != 'CompanyUser'"
            class="
              flex
              justify-center
              py-2.5
              px-1
              rounded
              transition
              duration-200
              hover-sidebar
            "
          >
            <img
              src="@/assets/icons/icon_user.svg"
              alt=""
              class="w-4 h-4 fill-color"
            />
          </router-link>
        </el-tooltip>
        <el-tooltip
          v-if="roleUser != 'ITaxGeneral' && roleUser != 'SaleAdmin'"
          class="item"
          effect="dark"
          content="Thống kê"
          placement="right"
        >
          <router-link
            :to="{name: 'dashBoard'}"
            class="
              flex
              py-2.5
              px-1
              rounded
              transition
              duration-200
              justify-center
              hover-sidebar
            "
          >
            <img
              src="@/assets/icons/icon_dash_board.svg"
              alt=""
              class="w-4 h-4 fill-color"
            />
          </router-link>
        </el-tooltip>

        <el-tooltip
          v-if="roleUser != 'ITaxGeneral' && roleUser != 'SaleAdmin'"
          class="item"
          effect="dark"
          content="Hóa đơn"
          placement="right"
        >
          <router-link
            :to="{name: 'invoices'}"
            class="
              flex
              justify-center
              py-2.5
              px-1
              rounded
              transition
              duration-200
              hover-sidebar
            "
          >
            <img
              src="@/assets/icons/icon_invoice.svg"
              alt=""
              class="w-4 h-4 fill-color"
            />
          </router-link>
        </el-tooltip>
        <el-tooltip
          v-if="roleUser != 'ITaxGeneral' && roleUser != 'SaleAdmin'"
          class="item"
          effect="dark"
          content="Báo cáo"
          placement="right"
        >
          <router-link
            :to="{name: 'reports'}"
            class="
              flex
              justify-center
              py-2.5
              px-1
              rounded
              transition
              duration-200
              hover-sidebar
            "
          >
            <img
              src="@/assets/icons/icon_report.svg"
              alt=""
              class="w-4 h-4 fill-color"
            />
          </router-link>
        </el-tooltip>
        <el-tooltip
          v-if="roleUser != 'ITaxGeneral' && roleUser != 'SaleAdmin'"
          class="item"
          effect="dark"
          content="Lịch sử"
          placement="right"
        >
          <router-link
            :to="{name: 'invoiceLog'}"
            class="
              flex
              justify-center
              py-2.5
              px-1
              rounded
              transition
              duration-200
              hover-sidebar
            "
          >
            <img
              src="@/assets/icons/icon_history.svg"
              alt=""
              class="w-4 h-4 fill-color"
            />
          </router-link>
        </el-tooltip>
        <el-tooltip
          v-if="roleUser != 'ITaxGeneral' && roleUser != 'SaleAdmin'"
          class="item"
          effect="dark"
          content="Hòm thư"
          placement="right"
        >
          <router-link
            :to="{name: 'mailBox'}"
            class="
              flex
              justify-center
              py-2.5
              px-1
              rounded
              transition
              duration-200
              hover-sidebar
            "
          >
            <img
              src="@/assets/icons/icon_mailbox.svg"
              alt=""
              class="w-4 h-4 fill-color"
            />
          </router-link>
        </el-tooltip>
        <el-tooltip
          v-if="roleUser != 'SaleAdmin'"
          class="item"
          effect="dark"
          content="Tổng hợp dữ liệu"
          placement="right"
        >
          <router-link
            :to="{name: 'invoice_summary'}"
            class="
              flex
              justify-center
              py-2.5
              px-1
              rounded
              transition
              duration-200
              hover-sidebar
            "
          >
            <img
              src="@/assets/icons/icon_summary_itax_admin.svg"
              alt=""
              class="w-4 h-4 fill-color"
            />
          </router-link>
        </el-tooltip>

        <el-tooltip
          v-if="roleUser != 'ITaxGeneral' && roleUser != 'SaleAdmin'"
          class="item"
          effect="dark"
          content="Kết nối cơ quan thuế"
          placement="right"
        >
          <router-link
            :to="{name: 'connect-gov'}"
            class="
              flex
              justify-center
              py-2.5
              px-1
              rounded
              transition
              duration-200
              hover-sidebar
            "
          >
            <img
              src="@/assets/icons/icon_bank.svg"
              alt=""
              class="w-4 h-4 fill-color"
            />
          </router-link>
        </el-tooltip>
        <el-tooltip
          v-if="roleUser == 'ITaxAdmin'"
          class="item text-black"
          effect="dark"
          content="Thống kê hệ thống"
          placement="right"
        >
          <router-link
            :to="{name: 'system-statistic'}"
            class="
              flex
              py-2.5
              px-1
              rounded
              transition
              duration-200
              justify-center
              hover-sidebar
            "
          >
          <i class="w-4 h-4 el-icon-setting"></i>
          </router-link>
        </el-tooltip>

        <el-tooltip
          v-if="getUserIndexRole == 6"
          class="item users-online"
          effect="dark"
          content="User Online"
          placement="right"
        >
          <div class="
              flex
              justify-center
              rounded
              transition
              duration-200
              hover-sidebar
              font-bold
              text-black
              w-9/12
            ">
            {{ total_online }}
          </div>
        </el-tooltip>

        <el-tooltip
          v-if="getUserIndexRole == 6"
          class="item pending-task"
          effect="dark"
          content="Jobs Queue"
          placement="right"
        >
          <div class="
              flex
              justify-center
              rounded
              transition
              duration-200
              hover-sidebar
              font-bold
              text-black
              w-9/12
            ">
            {{ taskCount }}
          </div>
        </el-tooltip>
      </nav>
    </div>
  </div>
</div>
