import { API_URL } from "@/consts";
import { URL as COMPANY_URL } from "@/api/company";
import { mapGetters } from "vuex";
import { USER_ROLE_INDEX } from "@/consts";

export default {
  data() {
    return {
      hideSidebar: true,
      taskCount: 0,
      taskManagementUrl: `${API_URL.replace('8088', '5555')}/task-management/tasks`,
      failedLookupInvoices: {
        count: 0,
        isUpdating: 0,
      },
      total_online: 0,
      user_emails: "",
      idInterval: null,
    };
  },
  methods: {
    changeSidebar(value) {
      this.hideSidebar = !value;
      this.$emit("changeSide", this.hideSidebar);
    },

    setSidebarWidth() {
      let windowSize = document.body.clientWidth;
      const HD_WIDTH = 1280;
      this.hideSidebar = windowSize > HD_WIDTH
      this.$emit("changeSide", this.hideSidebar);
    },

    async getSystemHealth() {
      const result = await this.$request({
        url: COMPANY_URL.SYSTEM_HEALTH,
        isHideLoading: true,
      });

      const res = result.data;
      if (res.code == 200) {
        this.failedLookupInvoices = res.data.failed_lookup_info_invoices;
        this.taskCount = res.data.job_queue_lenth;
        this.total_online = res.data.user.total_online;
        this.user_emails = res.data.user.latest_users_online;
      }
    },

    sendRequestRereadFailedLookupInvoices() {
      this.$request({
        url: COMPANY_URL.FAILED_LOOKUP_INFO_INVOICES,
      });
    },

    handleLookupAllInvoiceStatusFailed() {
      this.$swal({
        title: "Cập nhật trạng thái hóa đơn",
        text: "Thao tác này có thể làm giảm hiệu suất hệ thống, hãy đảm bảo thao tác được thực hiện vào khung giờ lượng truy cập thấp",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.failedLookupInvoices.isUpdating = true;
          this.sendRequestRereadFailedLookupInvoices();
        }
      });
    },

    handleReload() {
      if (this.getUserIndexRole == USER_ROLE_INDEX.ITAX_ADMIN && this.taskManagementUrl.indexOf('5555') == -1) {
        this.getSystemHealth();
        this.idInterval = setInterval(() => this.getSystemHealth(), 60000);
      }
    }
  },

  mounted() {
    this.setSidebarWidth();
    window.addEventListener("resize", this.setSidebarWidth);
    this.handleReload();
  },

  computed: {
    ...mapGetters([
      "getUserIndexRole",
      "getWindowFocusState",
    ]),
    roleUser() {
      return this.$store.state.setRoleUser.role;
    },
    routeName() {
      return this.$route.name;
    },
  },

  watch: {
    getWindowFocusState(state) {
      if (state) {
        this.handleReload(true);
      } else {
        clearInterval(this.idInterval);
      }
    },
    async $route() {
      this.setSidebarWidth();
    }
  }
};
