import changeLanguage from "@/components/changeLanguage.vue";
import { mapGetters } from "vuex";
import Select2 from "vue3-select2-component";
import { URL } from "@/api/company";
import auth from "@/api/auth";
import { LIST_ROUTE_COMPANY_NEED_PROTECT, COMPANY_LIMIT, USER_ROLE_INDEX } from "@/consts";
import searchInput from "@/components/searchInput";
import _ from "lodash";
import VueMultiselect from 'vue-multiselect'

export default {
  components: {
    changeLanguage,
    Select2,
    VueMultiselect,
    searchInput,
  },
  data() {
    return {
      searchCompanyLoading: false,
      typingTimer: 0,
      isInfor: false,
      userInfo: {},
      optionSelected: null,
      companySelect: [],
      showSelect: false,
      user_type: [
        "CompanyUser",
        "CompanyAdmin",
        "CompanySuperAdminSub",
        "CompanySuperAdmin",
        "ITaxGeneral",
        "ITaxAdmin",
      ],
      selectCompany: null,
      isLastPage: true,
      search: null,
      searchUserName: null,
    };
  },
  async created() {
    window.addEventListener('blur', this.outfocus)
    window.addEventListener('focus', this.focus)
    // Fetch data
    if (this.getAllCompanies.length) {
      this.selectCompany = this.getTargetCompany;
    }
    await this.getUser();
    this.updateOnlineStatus();
    this.getCompaniesInfo();
    this.search = this.getCompanySearch;
    this.searchUserName = this.getUserSearch;
    setInterval(() => {this.updateOnlineStatus(); }, 30000)
  },

  methods: {
    outfocus () {
      this.$store.dispatch('setWindowFocusState', false);
    },
    focus() {
      this.$store.dispatch('setWindowFocusState', true);
    },
    prepareDataForSelect(companies) {
      this.companySelect = companies.map((company) => {
        company.text = company.tenDoanhNghiep
        if (company.id > -1) {
          company.text += ' - ' + company.mst;
        }
        return company;
      });
      if (!this.isLastPage) {
        this.companySelect.push({
          text: "Tải thêm..."
        });
      }
    },

    async getUser() {
      const res = await this.$request({
        url: auth.GET_USER_INFO,
      });
      if (res.status == 200) {
        this.$store.dispatch("setAuthUser", res.data.data);
        localStorage.setItem("is_invite", false);
        if (res.data.data.is_invited) {
          this.$router.push({ name: "profile" });
          localStorage.setItem("is_invite", true);
        }
        this.$store.dispatch("setRoleUser", res.data.data.userType);
        this.userInfo = res.data.data;
        let indexRole = this.user_type.indexOf(this.userInfo.userType) + 1;
        if (this.userInfo.userType == 'SaleAdmin') {
          indexRole = 4
        }

        this.$store.dispatch("setIndexRoleUser", indexRole);
        if (indexRole >= USER_ROLE_INDEX.COMPANY_SUPER_ADMIN_SUB) {
          this.showSelect = true;
        }

        // Dashboard redirect
        if (indexRole == USER_ROLE_INDEX.ITAX_GENERAL) {
          this.$router.push({ name: "companyManagement" });
        }
      }
    },

    updateOnlineStatus () {
      this.$request({url: auth.UPDATE_ONLINE_STATUS.replace(':id', this.userInfo.id), method: "POST" });
    },

    async getCompaniesInfo(route = this.$route.name) {
      this.searchCompanyLoading = true
      const query = this.$store.getters.getQuery
      const keySearch = query.key_search
      query['minimum'] = true
      const result = await this.$request({
        url: URL.LIST_COMPANY,
        params: query
      });
      const res = result.data;

      if (res.code == 200) {
        if (res.data.data.length >= 1) {
          this.isLastPage = !res.data.next;
          let result = res.data.data
          // set company if exists

          if (res.data.previous) {
            // Append for load more case
            result = [...this.$store.getters.getAllCompanies, ...result]
          }
          this.$store.dispatch("setAllCompanies", result);
        } else {
          this.isLastPage = true
          this.$store.dispatch("setAllCompanies", [{
            id: -2,
            tenDoanhNghiep: "Không có kết quả"
          }]);

          if (keySearch == "") {
            // Redirect to homepage if no company found.
            if (LIST_ROUTE_COMPANY_NEED_PROTECT.indexOf(route) != -1) {
              this.$store.dispatch("setAllCompanies", []);
              this.showAlert();
            }
          }
        }

      }
      this.prepareDataForSelect(this.getAllCompanies);
      if (keySearch == "") {
        this.handleItaxSeeAllInvoice(this.$route.name);
      }
      this.searchCompanyLoading = false
    },
    
    async triggerSearch(query) {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(async () => {
        // Search trigger after 300ms if no more input
        this.$store.dispatch("setQuery", {
          'key_search': query,
          'limit': COMPANY_LIMIT,
          'page': 1
        });
        await this.getCompaniesInfo();
      }, 300);
    },

    async signout() {
      const result = await this.$request({
        url: URL.LOGOUT,
        method: "POST",
      });
      const res = result.data;

      if (res.code == 200) {
        this.$router.push({ name: "login" });
        this.$store.dispatch("setToken", null);
        this.$store.dispatch("setKeySearch", "");
        this.$store.dispatch("setKeySearchMailBox", "");
        this.$store.dispatch("setUserKeySearch", "");
        this.$store.dispatch("setInvoiceKeySearch", "");
        this.$store.dispatch("setEmailQltk", null);
        this.$store.dispatch("setUsersUser", null);
        this.$store.dispatch("setCompaniesSelected", null);
        this.$store.dispatch("setReportPeriod", null);
        this.$store.dispatch("setreportYear", null);
        this.$store.dispatch("setIsManual", false);
        this.$store.dispatch("setIsInvited", false);
        localStorage.clear();
      }
    },

    async handleSelectCompany(company) {
      let { id } = company;
      if (id == undefined) {
        // Load more button
        let newQuery = this.$store.getters.getQuery;
        newQuery.page += 1;
        this.$store.dispatch("setQuery", newQuery);
        await this.getCompaniesInfo();
        return;
      }

      // Close select
      this.$refs['companySelect'].deactivate()
      this.changeCompanySelected(company);
      // Reset query onclose
      this.$store.dispatch("setQuery", {
        'key_search': "",
        'limit': COMPANY_LIMIT,
        'page': 1
      });
      this.getCompaniesInfo();
    },

    changeCompanySelected(company) {
      if (company) {
        let { id, kieuKeKhai, tenDoanhNghiep, start_year } = company;
        this.$store.dispatch("setCompaniesSelected", id);
        this.$store.dispatch("setStartYear", start_year);
        this.$store.dispatch("setCompanyName", tenDoanhNghiep);
        this.$store.dispatch("setDeclarationType", kieuKeKhai);
        this.getDetailCompany(company.id)
      }
    },

    async getDetailCompany(id_company) {
      if (id_company== -1) return;
      const result = await this.$request({
        url: URL.EDIT_DETAIL_COMPANY.replace(":id", id_company),
      });

      const res = result.data;
      if (res.code == 200) {
        this.$store.dispatch("setTargetCompany", res.data);
      }
    },

    showAlert() {
      this.$swal({
        text:
          this.getUserIndexRole < USER_ROLE_INDEX.COMPANY_SUPER_ADMIN_SUB
            ? "Vui lòng liên hệ quản lý để tạo công ty"
            : "Vui lòng tạo công ty",
        title: "Bạn chưa có công ty",
        icon: "warning",
        timer: "3000",
        width: "450",
        padding: "10px",
      }).then(() => {
        this.$router.push({ name: "companyManagement" });
        return;
      });
    },

    handleItaxSeeAllInvoice(routeName) {
      if (this.getUserIndexRole == USER_ROLE_INDEX.ITAX_ADMIN) {
        if (
          ["listInvoices", "invoiceLog"].indexOf(routeName) != -1 &&
          this.getAllCompanies.length > 0
        ) {
          if (this.getAllCompanies[0].id == -1) return;
          // Append first option "Tất cả công ty"
          let data = _.cloneDeep(this.getAllCompanies);
          data.unshift(_.cloneDeep(data[0]));
          data[0].tenDoanhNghiep = "Tất cả công ty";
          data[0].id = "-1";
          this.$store.dispatch("setAllCompanies", data);
        } else if (this.getAllCompanies[0].id == -1) {
          // Remove option "Tất cả công ty"
          let data = _.cloneDeep(this.getAllCompanies);
          data.shift();
          this.$store.dispatch("setAllCompanies", data);
          if (!this.selectCurrentCompany || this.selectCurrentCompany == -1) {
            this.changeCompanySelected(this.getAllCompanies[0]);
          }
        }
      }
      if (!this.selectCurrentCompany) {
        this.changeCompanySelected(this.getAllCompanies[0]);
      }
    },

    searchInput() {
      this.$store.dispatch("setKeySearch", this.search);
    },

    searchInputUserName() {
      this.$store.dispatch("setUserKeySearch", this.searchUserName);
    },
  },
  computed: {
    ...mapGetters([
      "companies",
      "selectCurrentCompany",
      "getAllCompanies",
      "getUserIndexRole",
      "getTargetCompany",
      "getCompanySearch",
      "getUserSearch",
      "getQuery",
      "getDetailInvoice",
    ]),
    role() {
      return this.$store.state.setRoleUser.role;
    },
    routeName() {
      return this.$route.name;
    },
  },

  watch: {
    getAllCompanies(newVal) {
      this.prepareDataForSelect(newVal);
    },

    selectCurrentCompany(newVal) {
      const company_selected = this.getAllCompanies.filter(
        (company) => company.id == newVal
      );
      if (company_selected.length) {
        this.selectCompany = company_selected[0];
      }
      this.$store.dispatch("setIsManual", false);
      this.$store.dispatch("setIsUpdating", false);
      this.$store.dispatch("resetInvoicePaginatePage")
      this.$store.dispatch("resetInvoiceLogPaginatePage")
      this.$store.dispatch("resetMailBoxPaginatePage")
    },

    async $route(newVal) {
      this.search = this.getCompanySearch;
      this.searchUserName = this.getUserSearch;
      this.handleItaxSeeAllInvoice(newVal.name);
      // Disable updating if route changed
      this.$store.dispatch("setIsUpdating", false);
      this.$store.dispatch("setIsManual", false);

      // For checking company is null on route changed
      if (!this.selectCurrentCompany) {
        await this.getCompaniesInfo(newVal.name);
      }
    },
  },
};
